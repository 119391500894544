<template>
    <div class="member-2"
        style="">
        <div class="image-container">
            <img class="member-image"
                :v-if="dataSrc"
                :src="dataSrc" /></div>
        <div class="member-info">
            <div class="name">
                {{name}}
            </div>
            <div class="resort">
                {{resort}}
            </div>
            <div class="phone">
                {{phone}}
            </div>
            <div class="email">
                {{email}}
            </div>
        </div>
    </div>
</template>

<script>
    import {
    	isBlank
    }
    from 'voUtils/tools.js';
    import V from 'voUtils/V.js'


    import DateEx from 'voUtils/DateEx.js'

    export default {
    	voVueComponent: 'voffice-team-member-v2',
    	data() {
    		return {

    		}
    	},
    	props: {
    		name: String,
    		phone: String,
    		email: String,
    		resort: String,
    		dataSrc: String,

    	},
    	computed: {},

    	methods: {}
    }
</script>



<style scoped>
    .image-container {
    	height: 250px;
    	width: 250px;
    	margin: 0 auto;
    	margin-top: 20px;
    	display: flex;
    }

    .member-image {
    	width: 100%;
    	height: auto;
    	display: block;
    	margin: 0 auto;
    	max-height: 340px;
    	object-fit: cover;
    	object-position: top;
    	border-radius: 50%;
    	border: 2px solid darkgrey;
    }

    .member-info {
    	display: flex;
    	flex-direction: column;
    	justify-content: center;
    	min-height: 140px;
    	padding: 0 1em;
    	text-align: center;
    }
</style>